import React, { useEffect,useState } from "react";
import { NavLink } from "react-router-dom";
import lang from './Lang';

export default function Footer(props) {
  const [currLang, setcurrLang] = useState(lang['en']);

  useEffect(() => {
    if (props.language) setcurrLang(lang[props.language]);
  }, [props.language]);

  if(currLang === undefined) return ( <></>); // TODO: better fix for iphone

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md">
            <img className="d-block" src="/assets/icons/apple-touch-icon.png" width="68px" />
            Return2us
            <small className="d-block mb-3 text-muted">© 2022</small>
          </div>
          <div className="col-6 col-md">
            <h5>{currLang['information']}</h5>
            <ul className="list-unstyled text-small">
            {currLang && Object.keys(currLang['info']).map((key,index) => (
              <li key={index.toString()}>
                <NavLink 
                  end 
                  className={({ isActive }) => "" + (isActive ? " active" : "")} 
                  to={key}>{currLang['info'][key]}</NavLink>
              </li>
            ))}
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>{currLang['contact us']}</h5>
            <ul className="list-unstyled text-small">
            {currLang && Object.keys(currLang['contact']).map((key,index) => (
              <li key={index.toString()}>
                <NavLink 
                  end 
                  className={({ isActive }) => "" + (isActive ? " active" : "")} 
                  to={key}>{currLang['contact'][key]}</NavLink>
              </li>
            ))}
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>{currLang['language']}</h5>
            <ul className="list-unstyled text-small">
              <li><NavLink to="#se" onClick={e => props.handleSetLanguage('se')}>Swedish</NavLink></li>
              <li><NavLink to="#en" onClick={e => props.handleSetLanguage('en')}>English</NavLink></li>
              {/*<li><Link className="text-muted" to="#sp" onClick={e => props.handleSetLanguage('sp')}>Spanish</Link></li>*/}
            </ul>
          </div>         
        </div>
      </div>
    </footer>
    

  )

}