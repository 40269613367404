export default function body(props) {

  if (props.language === 'se') {
    return (
      <div>
        Genom att använda Return2us’s sajt och tjänster godkänner du att vi använder cookies. 
        Vi använder cookies för att förbättra användarbarheten och göra tjänsten personlig. 
        Vi använder oss även av tredje parts cookies från Facebook, Twitter och Google för att förenkla registering och inloggning vilket gör 
        <b>vår sajt inte så användbar utan cookies</b>. Vi hoppas att ni accepterar våra cookies men har självklart förståelse för om ni inte gör det.
      </div>
    )
  } else {
    return (
      <div>
        By using Return2us’s services and site, you agree to our Cookies use. We use cookies for purposes including analytics, personalisation. 
        We include third party services such as Facebook,Twitter and Google to provide an easy register and login which makes 
        <b>our product useless without cookies</b>. We hope that you will concent but understand if you don't.
      </div>
    )
  }
}